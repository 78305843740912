import React from 'react'
import Pic1 from  "../images/IMG10.jpg";
import Pic2 from  "../images/IMG11.jpg";
import Pic3 from  "../images/IMG12.jpg";
import Pic4 from  "../images/IMG13.jpg";
import Pic5 from  "../images/IMG14.jpg";
import Pic6 from  "../images/IMG15.jpg";
import Pic7 from  "../images/IMG16.jpg";
import Pic8 from  "../images/IMG17.jpg";
import Pic9 from  "../images/IMG18.jpg" ;
import Pic10 from  "../images/IMG19.jpg" ;
import Pic11 from  "../images/IMG20.jpg" ;
import Pic12 from  "../images/IMG21.jpg" ;
import Pic13 from  "../images/IMG22.jpg" ;
import Pic14 from  "../images/IMG23.jpg" ;
import Pic15 from  "../images/IMG24.jpg" ;
import Pic16 from  "../images/IMG25.jpg" ;
import Pic17 from  "../images/IMG26.jpg" ;
import Pic18 from  "../images/IMG27.jpg" ;
import Pic19 from  "../images/IMG28.jpg" ;
import Pic20 from  "../images/IMG29.jpg" ;
import Pic21 from  "../images/IMG30.jpg" ;
import Pic22 from  "../images/IMG31.jpg" ;
import Pic23 from  "../images/IMG32.jpg" ;
import Pic24 from  "../images/IMG33.jpg" ;
import Pic25 from  "../images/IMG34.jpg" ;
import Pic26 from  "../images/IMG35.jpg" ;
import Pic27 from  "../images/IMG36.jpg" ;
import Pic28 from  "../images/IMG37.jpg" ;
import Pic29 from  "../images/IMG38.jpg" ;
import Pic30 from  "../images/IMG39.jpg" ;
import Pic31 from  "../images/IMG40.jpg" ;
import Pic32 from  "../images/IMG41.jpg" ;
import Pic33 from  "../images/IMG42.jpg" ;
import Pic34 from  "../images/IMG43.jpg" ;
import Pic35 from  "../images/IMG44.jpg" ;
import Pic36 from  "../images/IMG45.jpg" ;



let imgArr =[Pic1, Pic2, Pic3, Pic4,Pic5,Pic6,Pic7,Pic8,Pic9,Pic10,Pic11,Pic12,Pic13,Pic14,Pic15,Pic16,Pic17,Pic18,Pic19,Pic20,Pic21,Pic22,Pic23,Pic24,Pic25,Pic26,Pic27,Pic28,Pic29,Pic30,Pic31,Pic32,Pic33,Pic34,Pic35,Pic36]  
const PhotoGallery = () => {
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setDimensions({
    width: window.innerWidth,
    height: window.innerHeight,
    });
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, [dimensions]);
  return (
    <div className='gallery' style={{display:"flex",gap:"1rem",flexWrap:"wrap", justifyContent:"flex-start",alignItems:"flex-start",padding:"2rem 9%"}}>
      {imgArr.map(ele=>{
        return <div style={{flex:"1 1 22%"}}> 
          {dimensions.width<500?
          <img src={ele} alt=".." style={{width:"100%", border:"5px inset blue", borderRadius:"5px", boxShadow:"0px 0px 10px gray"}}/>
            :
            <img src={ele} alt=".." style={{width:"100%",maxWidth:"300px", border:"5px inset blue", borderRadius:"5px", boxShadow:"0px 0px 10px gray"}}/>
          }</div>
      })}
    </div>
  )
}

export default PhotoGallery
